.App {
  max-width: 640px;
  overflow-x: hidden;
  margin: 0 auto 20px;

  .with-margin {
    margin: 0.3em 1em;

    h4 {
      margin-top: 16px;
    }
  }

  .vertical-margin {
    margin-top: 1.5em;
    margin-bottom: 1em;
  }

  .form-input {
    margin-bottom: 1.5em;
  }

  .MuiOutlinedInput-root {
    &:hover:not(.Mui-error):not(.Mui-disabled) {
      .MuiOutlinedInput-notchedOutline {
        border-color: var(--blue600);
      }
    }

    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-width: 1px;
      }

    }
  }

  .MuiButton-root {
    &:hover {
      box-shadow: none;
    }
  }

  .input-margin {
    margin: 1.5em 0;
  }

  .error-banner {
    margin-bottom: 1em;
    padding: 0.5em 1em;
    border-radius: 10px;
    border-width: 1px;
    border-style: solid;
    color: white;
    border-color: var(--red600);
    background-color: var(--red600);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);


    .MuiTypography-root {
      padding-left: 16px;
    }
  }
}

a {
  color: inherit;
  text-decoration: inherit;
}
